import * as React from 'react';
import { useState } from 'react';
import cx from 'classnames';
import SearchBar from 'containers/SearchBar/SearchBar';
import styles from './Header.scss';

function Header() {
  const [showSearch, setShowSearch] = useState(false);
  function toggleSearchBar() {
    setShowSearch(!showSearch);
  }

  return (
    <header
      className={cx(styles.container, 'row', {
        [styles['is-full-height']]: showSearch,
      })}
    >
      <a href="https://www.cnyes.com/" className={styles.logo} />
      <a href="/" className={cx(styles['logo-talk'], styles.mobile)} />
      <div style={{ flex: 1 }} />
      <div
        className={cx(styles['search-icon'], {
          [styles['is-open']]: showSearch,
        })}
        onClick={toggleSearchBar}
      />
      {showSearch && <SearchBar searchCompletedCb={toggleSearchBar} />}
    </header>
  );
}

export default React.memo(() => <Header />, () => true);
