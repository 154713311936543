import * as React from 'react';
import { useEffect } from 'react';
import FavStar from 'containers/FavStar';
import useSharedQuote from 'hooks/sharedQuotes';
import styles from './m-symbol-info.scss';

interface MobileSymbolInfoProps {
  symbol: string;
  onMenu?: () => void;
  onTitleClick?: () => void;
  onStarClick?: () => void;
  actionIcon?: string;
  style?: React.CSSProperties;
}

function up(cx: string | undefined) {
  return `${cx} ${styles.up}`;
}

function down(cx: string | undefined) {
  return `${cx} ${styles.down}`;
}

function MobileSymbolInfo({ symbol, onMenu, onTitleClick, style, actionIcon }: MobileSymbolInfoProps) {
  const [quote, , setStaticSymbol] = useSharedQuote();
  const data: Quote = quote[symbol] || {
    symbol: '--:---:--',
    name: '---',
    ch: 0,
    chp: 0,
    lp: 0,
  };
  let colorCx = cx => cx;
  if (data.ch > 0) {
    colorCx = up;
  } else if (data.ch < 0) {
    colorCx = down;
  }

  useEffect(() => {
    if (symbol) {
      setStaticSymbol(symbol);
    }
  }, [symbol]);

  return (
    <div className={colorCx(`row ${styles.container}`)} style={style}>
      <FavStar symbol={data.symbol} name={data.name} />
      <span className={`${styles.info} row`} onClick={onTitleClick}>
        <span className={`${styles.label} row`}>
          <h2>{String(data.symbol).split(':')[1]}</h2>
          <h1>{data.name}</h1>
        </span>
        <div className={`${styles.quote} col`}>
          <b className={colorCx('')}>{(data.isTrading ? data.lp : data.prevClosePrice) || '--'}</b>
          <span className="row">
            <div className={colorCx('')}>{data.ch}</div>
            <div className={colorCx('')}>{data.chp}%</div>
          </span>
        </div>
      </span>
      {actionIcon && <i onClick={onMenu} className={`${styles[actionIcon]}`} style={{ marginRight: 12 }} />}
    </div>
  );
}

export default MobileSymbolInfo;
