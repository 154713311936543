import React from 'react';
import getty from 'anue-fe-sdk/getty';
import useAuth, { isLogin } from 'hooks/auth';
import Variation from 'constant/variation';
import { SEND_PROJ_TARGET, sendEventLog } from 'utils/analytics';
import sso from 'anue-fe-sdk/sso';
import styles from './chat-input-menu.scss';
import sendDataLayer from 'fe-common-library/dest/utils/sendDataLayer';

export default function() {
  const [auth, login, logout] = useAuth();
  const loggedIn = isLogin();
  const avatar =
    getty(sso.getCachedUser(), ['profile', 'avatar']) ||
    getty(auth, ['profile', 'avatar']) ||
    require('assets/icon-login-avatar.svg');

  return (
    <div
      onClick={() => {
        if (!loggedIn) {
          login();
          sendEventLog(Variation.stockChannelName, '會員 click', '登入', {}, SEND_PROJ_TARGET);
          sendDataLayer({
            dataPrefix: SEND_PROJ_TARGET,
            eventName: 'Click_Member',
            clickItem: '登入',
          });
        } else {
          const shouldLogout = window.confirm('是否要登出');
          if (shouldLogout) {
            logout();
            sendEventLog(Variation.stockChannelName, '會員 click', '登出', {}, SEND_PROJ_TARGET);
            sendDataLayer({
              dataPrefix: SEND_PROJ_TARGET,
              eventName: 'Click_Member',
              clickItem: '登出',
            });
          }
        }
      }}
    >
      <img className={styles.avatar} src={avatar} />
    </div>
  );
}
