import * as React from 'react';
import { useState, useEffect } from 'react';
import useDevice from 'hooks/device';
import { dispatch } from 'hooks/ui';
import getty from 'anue-fe-sdk/getty';
import useNews from 'hooks/news';
import Header from 'widgets/Header';
import AsideRight from 'widgets/Aside/AsideRight';
import NewsByType from 'containers/NewsListByType/NewsListByType';

import styles from './MarketApp.pwa.scss';
import MobileContextMenu from 'widgets/MobileContextMenu';

interface MarketAppProps {
  match: {
    params: {
      id: string;
      tab?: string;
    };
  };
  location: {
    query: {
      chartOnly: string;
    };
    key: string;
    pathname: string;
    search: string;
    hash: string;
    state: any;
  };
  children: any;
}

function MarketApp(props: MarketAppProps) {
  const [device] = useDevice();
  const [tab, setTab] = useState(3);
  const [news, , , fetchVideoNews] = useNews();
  const { children } = props;
  const id = props.match.params.id || 'TWS:TSE01:INDEX';

  if (!id) {
    console.log('Market app render blank page since the id is empty %o', id);

    return null;
  }

  useEffect(() => {
    fetchVideoNews();
    // @ts-ignore
    if (typeof window.safari !== undefined && device.layout === 'mobile') {
      document.body.style.position = 'fixed';
      document.body.style.top = '0';
      document.body.style.left = '0';
      document.body.style.height = `${device.height}px`;
      document.body.style.width = `100vw`;
      document.addEventListener(
        'touchmove',
        function(event) {
          // @ts-ignore
          event = event.originalEvent || event;
          // @ts-ignore
          if (event.scale !== 1) {
            event.preventDefault();
          }
        },
        false
      );
    }
  }, []);

  // get live video on page star
  useEffect(() => {
    for (const i in news.video) {
      const v = news.video[i];
      if (v.isLive) {
        dispatch({
          type: 'video',
          payload: {
            url: `https://www.youtube.com/watch?v=${v.id}`,
            channelTitle: v.channelTitle,
            visible: true,
          },
        });
        break;
      }
    }
  }, [getty(news, ['video', 'length'])]);

  function renderTab() {
    switch (tab) {
      case 0:
        return (
          <section className={styles.mnews}>
            <NewsByType key={`news-${id}-${Date.now()}`} type="related" symbol={id} />
          </section>
        );
      case 1:
        return (
          <section className={styles.mnews}>
            <NewsByType key={`news-realteim-${Date.now()}`} type="realtime" />
          </section>
        );
      case 2:
        return (
          <section className={styles.mnews}>
            <NewsByType key={`news-video-${Date.now()}`} type="video" />
          </section>
        );
      case 3:
        return (
          <section>
            <AsideRight />
          </section>
        );
    }
  }

  return (
    <div
      className={`${styles.mobile} col`}
      style={{
        height: device.height,
      }}
    >
      <MobileContextMenu symbol={id}>{children}</MobileContextMenu>
      <nav>
        {['新聞相關', '即時新聞', '財經影音', '聊天'].map((text, i) => (
          <a key={text} onClick={() => setTab(i)} data-active={i === tab}>
            {text}
          </a>
        ))}
      </nav>
      <img className={styles.logo} src={require('assets/anue-logo.svg')} alt="Anue鉅亨 | 股市talk" />
      {renderTab()}
    </div>
  );
}

export default React.memo(
  (props: MarketAppProps) => <MarketApp {...props} />,
  (prev, next) => prev.location.pathname === next.location.pathname
);
