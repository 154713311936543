import React, { Component } from 'react';
import MarketApp from 'components/MarketApp/MarketApp';
import { CookiesProvider } from 'react-cookie';
import GlobalConfig from 'constant/globalConfig';
import OTAC from 'anue-fe-sdk/OTAC';
import { pageview, sendEventLog } from '../../utils/analytics';
import AnalyticsWrapper from '../../components/AnalyticsWrapper/AnalyticsWrapper';
import DFPProviderWrapper from '../../components/DFP/DFPProviderWrapper';
import HelmetWithGA from '../../components/HelmetWithGA/HelmetWithGA';
import { SITE_TITLE } from '../../helpers/meta';

import './App.scss';

OTAC.setServer(GlobalConfig.loginUrl);

interface AppProps {
  match: {
    params: {
      id: string;
      tab?: string;
    };
  };
  location: {
    query: {
      chartOnly: string;
    };
    key: string;
    pathname: string;
    search: string;
    hash: string;
    state: any;
  };
  children: any;
  loadVisitorId?: () => void;
}

class App extends Component<AppProps> {
  componentDidMount() {
    const { loadVisitorId } = this.props;
    if (loadVisitorId) {
      loadVisitorId();
    }
  }

  render() {
    const { match, location } = this.props;

    return (
      <CookiesProvider>
        <DFPProviderWrapper>
          <AnalyticsWrapper sendEvent={sendEventLog}>
            <HelmetWithGA
              config={{ title: SITE_TITLE }}
              location={location}
              pageview={pageview}
              ignoreTitle={''}
              ignoreWhenFirstChange={false}
            />
            <MarketApp location={location} match={match}>
              {this.props.children}
            </MarketApp>
          </AnalyticsWrapper>
        </DFPProviderWrapper>
      </CookiesProvider>
    );
  }
}

export default App;
