import * as React from 'react';
import { useState } from 'react';
import MobileSymbolInfo from 'containers/MobileSymbolInfo';
import StockPanel from 'containers/StockPanel/StockPanel';
import styles from './mobile-context-menu.scss';

interface MobileContextMenuProps {
  symbol: string;
  children: any;
}

function MobileContextMenu({ symbol, children }: MobileContextMenuProps) {
  const [showChart, setShowChart] = useState<number>(-1);
  const [showPortfolio] = useState<boolean>(false);

  function toggleChart() {
    setShowChart(showChart === 1 ? 0 : 1);
  }

  function addToPortfolio() {}

  return (
    <div className={styles.container}>
      <MobileSymbolInfo symbol={symbol} onMenu={toggleChart} onTitleClick={toggleChart} onStarClick={addToPortfolio} />
      <div
        className={styles.overlay}
        style={{
          height: window.innerHeight - 108,
          opacity: showChart > 0 || showPortfolio ? 1 : 0,
          pointerEvents: showChart > 0 || showPortfolio ? 'all' : 'none',
        }}
      >
        {showChart > -1 && <StockPanel symbol={symbol} main={children} />}
      </div>
    </div>
  );
}

export default React.memo(
  (props: MobileContextMenuProps) => <MobileContextMenu {...props} />,
  (prev, next) => prev.symbol === next.symbol && prev.children === next.children
);
